import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import UserContext from '../components/auth/context';
import { UserData } from '../components/auth/types';
import BlankScreen from '../components/structure/BlankScreen';
import MainLayout from '../components/structure/MainLayout';
import claimActions from '../redux/actions/claimActions';
import { REPLACE_CLAIM, UPDATE_CLAIM } from '../redux/reducers/claimReducer';
import schemaVisioAvocat from '../schemas/visioAvocat';
import { SimpleFormArgs } from '../types/Forms';
import { ClaimData } from '../types/claimdata';
import { isObject, pick, sleep } from '../utils/misc';

type stepNameFunction = (
  formData: unknown,
  userData?: unknown,
  submitData?: unknown
) => string;
type stepNameArg = string | stepNameFunction;

const App = () => {
  const { t } = useTranslation(['common', 'form']);
  const tForm = (message) => t(message, { ns: 'form' });

  const { user: userFromContext, mutateUser } = useContext(UserContext);
  const userData = userFromContext as UserData;
  const router = useRouter();
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [submitting, setSubmit] = useState(false);
  const claimData = useSelector((state: { claim: object }) => state.claim);
  const statusData = useSelector(
    (state: { status: { sendDataStatus: string; error: string } }) =>
      state.status
  );
  // const userServices = Object.keys(userData?.services || []);

  const claimInfos = schemaVisioAvocat;

  const { title, schema } = claimInfos;
  const data = schema?.[step];
  const validate = data?.validate || null;

  const initializeData = async () => {
    if (!claimInfos.initializeData || !userFromContext) return;
    const initData = await claimInfos.initializeData(userData);
    dispatch({
      type: UPDATE_CLAIM,
      data: {
        ...claimData, // important l'init prend le pas sur les données du form (à modifier plus tard éventuellement)
        ...initData,
      },
    });
  };

  useEffect(() => {
    if (claimInfos?.initializeData) {
      initializeData();
    }
  }, [claimInfos?.initializeData, JSON.stringify(userData)]);

  /**
   * TODO mettre ça dans un hook qui gère le status, le claim data et le claim mutation (pour remplacer Redux)
   * @param {String|function} stepName nom de l'étape suivante ou fonction qui renvoie un String correspondant au nom de l'étape suivante
   * @param {Object} postedData data postée dans le form
   * @param {Boolean} submit faut-il soumettre le formulaire à ce stade
   */
  const handleGoTo = useCallback(
    async (stepName: stepNameArg, postedData = null, submit = true) => {
      let submitData;
      // soumission du formulaire
      if (
        submit &&
        // si le step a une propriété submit
        Object.prototype.hasOwnProperty.call(schema[step], 'submit') &&
        // et qu'il n'y a pas de condition ou que la condition retourne `true`
        (typeof schema[step].submit.condition !== 'function' ||
          schema[step].submit.condition({ ...claimData, ...postedData }))
      ) {
        setSubmit(true);
        // TODO mettre ça dans un helper
        const dataToSend = pick(
          { ...claimData, ...postedData },
          schema[step].submit.variables
        );

        const cleanData = (dataToClean) =>
          Object.entries(dataToClean).reduce(
            (
              origin,
              [key, value]: [
                string,
                { id: string; inputValue: string } | string | number | boolean,
              ]
            ) => {
              const nuValues = Array.isArray(origin)
                ? [...origin]
                : { ...origin };
              if (Array.isArray(value)) {
                nuValues[key] = cleanData(value);
              } else if (
                !!value &&
                isObject(value) &&
                'inputValue' in (value as object) &&
                'id' in (value as object)
              ) {
                nuValues[key] = (value as { id: string }).id;
              } else if (value !== null && value !== undefined) {
                // on ne fait pas if(value) pour garder les valeurs `false`
                nuValues[key] = value;
              }
              return nuValues;
            },
            Array.isArray(dataToClean) ? [] : {}
          );
        // recherche et clean des fichiers
        const cleanedDataToSend = cleanData(dataToSend);

        const basePath = schema[step].submit.path;
        const path = // introduction du path dynamique pour le submit
          typeof basePath === 'function' ? basePath(claimData) : basePath;

        try {
          submitData = await claimActions.submitClaim(
            cleanedDataToSend,
            path
          )(dispatch);
          await mutateUser();
          await initializeData();
          await sleep(500); // pour éviter les redirections maladives quand les donénes ne sont pas encore updatées.
          // sleep ?
        } catch (error) {
          setSubmit(false);
        }
      }

      // on retrouve la prochaine étape
      const finalName =
        typeof stepName === 'function'
          ? stepName({ ...claimData, ...postedData }, userData, submitData)
          : stepName;

      if (finalName === 'home') {
        if (router.route === '/') {
          window.location.reload();
          return;
        }
        router.push('/');
        return;
      }
      if (finalName.indexOf('redirect:') === 0) {
        router.push(finalName.replace('redirect:', ''));
        return;
      }
      let stepNum = 0;

      const element = schema.find((el) => el.name === finalName);
      // gestion de l'auto submit, on va vider les champs des écrans qui sont en auto submit
      // pour éviter justement qu'ils se resoumettent à nouveau automatiquement.
      if (element?.data.autoSubmit) {
        const newClaimData = { ...claimData, ...postedData };
        let changes = 0;
        element.data.formSchema.fields.forEach((el) => {
          if (newClaimData[el.name]) {
            // newClaimData[el.name] = null;
            delete newClaimData[el.name];
            changes += 1;
          }
        });
        if (changes > 0) {
          dispatch({ type: REPLACE_CLAIM, data: newClaimData });
        }
      }
      stepNum = schema.indexOf(element);

      setStep(stepNum);
      setSubmit(false);
    },
    [step, JSON.stringify(claimData)]
  );

  const goBackwards = () => {
    handleGoTo(data.data.prev || 'home', {}, false);
  };

  if (!claimInfos) {
    return <BlankScreen />;
  }

  const Component = data.component as FC<SimpleFormArgs>;

  // on détermine certains statuts qui vont faire varier l'affichage (ex. envoi de données, erreur, etc.)
  const showContent = !submitting && statusData.sendDataStatus !== 'error'; // statusData.sendDataStatus === 'idle';
  const errorMessage =
    statusData.sendDataStatus === 'error' ? t(statusData.error) : null;
  const isLoading = submitting || statusData.sendDataStatus === 'loading';

  const completionWidth = `${data.completion}vw`;

  return (
    <MainLayout title={title ? t(title) : ''} onBack={goBackwards}>
      <div className="bg-caarlgreen h-2" style={{ width: completionWidth }} />
      {errorMessage && (
        <div className="text-red-500 text-center mt-32">{errorMessage}</div>
      )}
      {isLoading && (
        <div className="text-grey-100 flex text-center items-center justify-center mt-32 flex-wrap box-content">
          <ClipLoader size={60} />
          <div className="w-full mt-4">
            {tForm("Veuillez patienter pendant l'envoi des données...")}
          </div>
        </div>
      )}
      {/* <pre>{JSON.stringify(claimData, null, 2)}</pre> */}
      {showContent && (
        <Component
          goTo={handleGoTo}
          claimData={claimData as ClaimData}
          {...data.data}
          validate={validate}
          actionMapper={{
            translate: (x) => tForm(x),
            translateErrors: (x) =>
              x.map((err) => ({
                ...err,
                message: err.message ? tForm(err.message) : undefined,
              })),
          }}
          step={step}
        />
      )}
    </MainLayout>
  );
};

export async function getServerSideProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'form'])),
      // Will be passed to the page component as props
    },
  };
}

App.needAuth = true;

export default App;
