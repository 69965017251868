import { useEffect, useState } from 'react';
import { FaPhone } from 'react-icons/fa';

const Juridicall = ({ goTo, claimData }) => {
  const [render, setRender] = useState(false);

  const { insurer } = claimData;

  const tels = {
    juridica: ['+33130099772', '01 30 09 97 72'],
    solucia: ['not yet', 'not yet'],
  };

  useEffect(() => {
    if (!claimData.userId) return;
    if (claimData.appointment?.data?.date) {
      goTo('meeting_nextmeeting');
    }

    if (!claimData.prebook || claimData.appointment) {
      goTo('choix_procedure');
    }
    if (!render) {
      setRender(true);
    }
  }, [claimData.userId, claimData.prebook, claimData.appointment, render]);

  if (!render) return null;

  return (
    <div className="container-centered">
      <div className="text-title">Votre question juridique</div>
      <div className="text-normal mt-8">
        Appelez notre service de juristes pour avoir des réponses en temps réel.
        <br />
        Si votre question nécessite l&apos;intervention d&apos;un avocat, nous
        vous ouvrirons notre application vous permettant de réserver un
        rendez-vous pour une consultation avec un avocat.
      </div>
      <div className="text-center mt-12">
        <a
          className="btn flex mx-auto items-center w-fit py-4 px-8"
          href={`tel:${tels[insurer][0]}`}
        >
          <FaPhone className="h-8 w-8 mr-5" />
          appeler le {tels[insurer][1]}
        </a>
      </div>
    </div>
  );
};

export default Juridicall;
