import cookieCutter from 'cookie-cutter';
import { useContext, useEffect, useState } from 'react';
import { FaBars, FaChevronLeft } from 'react-icons/fa';
import UserContext from '../auth/context';

const NavTitle = ({ title = null }) => {
  if (title) {
    return (
      <div className="font-bold text-caarlblue text-lg leading-none">
        {title}
      </div>
    );
  }
  return (
    <img className="w-28 mx-auto" src="/images/caarl_logo.svg" alt="Caarl" />
  );
};

const Nav = (props) => {
  const { logout } = useContext(UserContext);
  const [externalMenu, setExternalMenu] = useState([]);

  useEffect(() => {
    const externalCookie = cookieCutter.get('caarl-menu');
    if (externalCookie) {
      setExternalMenu(JSON.parse(externalCookie));
    }
  }, []);

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
  };

  return (
    <nav className="flex justify-between items-center bg-white h-16 relative">
      <div className="text-left flex-shrink-0 flex-grow-0 w-20">
        {props.onBack && (
          <button
            type="button"
            aria-label="back"
            onClick={props.onBack}
            className="whitespace-no-wrap font-normal text-caarlblue text-2xl relative inline-block align-middle p-5"
          >
            <FaChevronLeft className="h-6 w-6" />
          </button>
        )}
      </div>
      <div className="text-center flex-grow flex-shrink">
        <NavTitle title={props.title} />
      </div>
      <div className="text-right  flex-shrink-0 flex-grow-0 w-20">
        <button
          type="button"
          aria-label="menu"
          className="menu-toggle text-caarlblue p-6 h-16"
        >
          <FaBars className="h-6 w-6" />
        </button>
        <div className="menu-content bg-white shadow w-64 absolute right-0 font-normal z-10">
          <ul className="list-reset">
            {externalMenu.map((mElement) => (
              <li key={mElement.link}>
                <a
                  type="button"
                  href={mElement.link}
                  className="block p-4 text-caarlblue border-white hover:pl-6 hover:border-caarlblue border-r-4 w-full text-right"
                >
                  {mElement.label}
                </a>
              </li>
            ))}
            <li>
              <button
                type="button"
                onClick={handleLogout}
                className="block p-4 text-caarlblue border-white hover:pl-6 hover:border-caarlblue border-r-4 w-full text-right"
              >
                Déconnexion
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
