// import {
//   componentTypes,
//   validatorTypes,
// } from '@data-driven-forms/react-form-renderer';
import {
  componentTypes,
  validatorTypes,
  type Schema,
} from '@data-driven-forms/react-form-renderer';
import axios from 'axios';
import dayjs from 'dayjs';
import Message from '../components/claims/Message';
import SimpleContent from '../components/claims/SimpleContent';
import SimpleForm from '../components/claims/SimpleForm';
import ChooseDate from '../components/claims/meeting/ChooseDate';
import ChooseLawyer from '../components/claims/meeting/ChooseLawyer';
import ChooseProcedure from '../components/claims/meeting/ChooseProcedure';
import ChooseSpeciality from '../components/claims/meeting/ChooseSpeciality';
import Juridicall from '../components/claims/meeting/Juridicall';
import MeetingList from '../components/claims/meeting/MeetingList';
import SignAndConfirm from '../components/claims/meeting/SignAndConfirm';
import ChoiceButton from '../components/claims/misc/ChoiceButton';
import FileHelper from '../components/structure/elements/FileHelper';
import { API_URL } from '../config/config';
import { ClaimSchema } from '../types/Forms';
import { ClaimData, specialitiesDef } from '../types/claimdata';

const procedureForm = {
  fields: [
    {
      component: 'choice-list',
      name: 'procedure',
      isRequired: true,
      options: [
        {
          value: 'meeting',
          icon: '/images/icons/caarl_outline.svg',
          label: 'Je choisis un avocat du réseau Caarl',
          className: 'text-white',
          sub: 'Vous n’aurez pas d’argent à avancer, la consultation est comprise dans votre contrat.',
        },
        {
          value: 'ownlawyer',
          icon: '/images/icons/person.svg',
          label: 'Je choisis mon propre avocat',
          sub: 'Dans ce cas, vous devrez régler les honoraires de votre avocat puis nous envoyer sa facture. Nous remboursons à hauteur de 300 euros/an.',
          className: '!bg-transparent border-2',
        },
      ],
      validate: [{ type: 'required', message: 'Vous devez valider.' }],
    },
  ],
};

const specialityForm: Schema = {
  fields: [
    {
      component: 'choice-list',
      name: 'speciality',
      isRequired: true,
      buttonComponent: ChoiceButton,
      options: [],
      actions: {
        // validate: [
        //   'translateErrors',
        //   [{ type: 'required', message: 'Vous devez valider.' }],
        // ],
      },
    },
  ],
};

const commentsForm: Schema = {
  fields: [
    {
      name: 'clientComment',

      component: componentTypes.TEXTAREA,
      maxLength: 500,
      isRequired: true,
      inputClass:
        'mt-1 mb-6 block w-full border-grey-100 border h-80 rounded-md',
      actions: {
        label: ['translate', 'Votre problématique'],
        validate: [
          'translateErrors',
          [
            {
              type: validatorTypes.REQUIRED,
              message: 'Champ obligatoire',
            },
          ],
        ],
      },
    },
    {
      component: componentTypes.FIELD_ARRAY,
      name: 'documents',
      fieldKey: 'field_array',
      initializeOnMount: true,

      actions: {
        title: ['translate', 'Ajouter des documents'],
        addLabel: ['translate', '+ Ajouter un document'],
        removeLabel: ['translate', 'supprimer'],
      },
      description: '5 documents max.',
      minItems: 0,
      maxItems: 5,
      validate: [
        {
          type: validatorTypes.MAX_LENGTH,
          threshold: 5,
          message: ((value) =>
            `pas plus de ${value} document(s).`) as unknown as string, // TODO tell data driven forms this is a mistake
        },
      ],
      fields: [
        {
          component: 'file-upload',
          isRequired: true,
          btnClass: 'btn m-1 text-sm !bg-black',
          labelClass: 'h-5 block',
          className: 'file-upload',
          helperText: <FileHelper />,

          actions: {
            btnLabel: ['translate', 'Choisir un fichier'],
            noFileLabel: ['translate', 'Aucun fichier choisi'],
            validate: [
              'translateErrors',
              [
                { type: 'file-required', message: 'Champ obligatoire' },
                {
                  type: 'docfile',
                  message:
                    'Les formats acceptés sont : png, jpg, jpeg, pdf, doc, docx',
                },
              ],
            ],
          },
        },
      ],
    },
  ],
};

const ownLawyerForm = {
  fields: [
    {
      name: 'firstName',
      label: 'Prénom',
      component: componentTypes.TEXT_FIELD,
      type: 'text',
      isRequired: true,
      //   icon: '/images/icons/at.svg',

      validate: [
        {
          type: validatorTypes.REQUIRED,
          message: 'Champ obligatoire',
        },
      ],
    },
    {
      name: 'lastName',
      label: 'Nom de famille',
      component: componentTypes.TEXT_FIELD,
      type: 'text',
      isRequired: true,
      //   icon: '/images/icons/at.svg',

      validate: [
        {
          type: validatorTypes.REQUIRED,
          message: 'Champ obligatoire',
        },
      ],
    },
    {
      name: 'firmName',
      label: 'Nom du cabinet',
      component: componentTypes.TEXT_FIELD,
      type: 'text',
      isRequired: true,
      //   icon: '/images/icons/at.svg',

      validate: [
        {
          type: validatorTypes.REQUIRED,
          message: 'Champ obligatoire',
        },
      ],
    },
    {
      name: 'address',
      label: 'Adresse',
      component: componentTypes.TEXT_FIELD,
      type: 'text',
      isRequired: true,
      //   icon: '/images/icons/at.svg',

      validate: [
        {
          type: validatorTypes.REQUIRED,
          message: 'Champ obligatoire',
        },
      ],
    },
    {
      name: 'city',
      label: 'Ville',
      component: componentTypes.TEXT_FIELD,
      type: 'text',
      isRequired: true,
      //   icon: '/images/icons/at.svg',

      validate: [
        {
          type: validatorTypes.REQUIRED,
          message: 'Champ obligatoire',
        },
      ],
    },
    {
      name: 'postalCode',
      label: 'Code Postal',
      component: componentTypes.TEXT_FIELD,
      type: 'text',
      isRequired: true,
      //   icon: '/images/icons/at.svg',

      validate: [
        {
          type: validatorTypes.REQUIRED,
          message: 'Champ obligatoire',
        },
        {
          type: validatorTypes.PATTERN,
          // eslint-disable-next-line no-useless-escape
          pattern: /^[0-9]{5}$/gm,
          message: "Ce code postal n'est pas au bon format",
        },
      ],
    },
    {
      name: 'email',
      label: 'Adresse email',
      component: componentTypes.TEXT_FIELD,
      type: 'email',
      isRequired: true,
      // icon: <FaAt />,

      validate: [
        {
          type: validatorTypes.REQUIRED,
          message: 'Vous devez indiquer une adresse email',
        },
        {
          type: validatorTypes.PATTERN,
          pattern:
            // eslint-disable-next-line no-useless-escape
            /^(?:(?:[\w`~!#$%^&*\-=+;:{}'|,?\/]+(?:(?:\.(?:"(?:\\?[\w`~!#$%^&*\-=+;:{}'|,?\/\.()<>\[\] @]|\\"|\\\\)*"|[\w`~!#$%^&*\-=+;:{}'|,?\/]+))*\.[\w`~!#$%^&*\-=+;:{}'|,?\/]+)?)|(?:"(?:\\?[\w`~!#$%^&*\-=+;:{}'|,?\/\.()<>\[\] @]|\\"|\\\\)+"))@(?:[a-zA-Z\d\-]+(?:\.[a-zA-Z\d\-]+)*|\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])$/gm,
          message: "Cette adresse email n'est pas au bon format",
        },
      ],
    },
    {
      name: 'telephone',
      label: 'Numéro de téléphone',
      component: componentTypes.TEXT_FIELD,
      type: 'text',
      isRequired: true,
      // icon: '/images/icons/at.svg',

      validate: [
        {
          type: validatorTypes.REQUIRED,
          message: 'Vous devez indiquer un numéro de téléphone',
        },
        {
          type: validatorTypes.PATTERN,
          // eslint-disable-next-line no-useless-escape
          pattern: /^((\+)33|0)[1-9](\d{2}){4}$/,
          message: "Ce numéro de téléphone n'est pas au bon format",
        },
      ],
    },
  ],
};

const ownLawyerFiles = {
  fields: [
    {
      component: 'file-upload',
      name: 'factureFile',
      label: 'votre facture',
      helperText:
        'Le remboursement est pris en charge à hauteur de 300€ TTC par votre assurance.',
      isRequired: true,
      validate: [{ type: 'file-required' }, { type: 'docfile' }],
    },
  ],
};

const ownLawyerSchema: ClaimSchema = [
  {
    name: 'ownlawyer_disclaimer',
    component: SimpleContent,
    completion: 30,
    data: {
      title: 'Vous contactez votre avocat personnel :',
      content: (
        <div>
          <div className="text-center">
            <img
              src="/images/icons/person.svg"
              className="h-20 w-20 mt-6 mx-auto"
              alt="avocat personnel"
            />
          </div>
          <p className="my-4">Vous devez prendre contact avec votre avocat.</p>

          <p className="my-4">
            Suite à la consultation, vous devez nous faire parvenir la facture
            sur cette interface.
          </p>

          <p className="my-4">
            Dans le cadre de votre contrat, cous serez remboursé à hauteur de
            300 € TTC.
          </p>

          <button
            type="button"
            data-trigger="ownlawyer_form"
            className="text-caarlblue underline text-left"
          >
            J’ai déjà eu une consultation juridique avec mon avocat et je
            souhaite me faire rembourser.
          </button>
        </div>
      ),
      prev: 'choix_procedure',
    },
  },
  {
    name: 'ownlawyer_form',
    component: SimpleForm,
    completion: 60,
    data: {
      title: 'Les coordonnées de votre avocat',
      formSchema: ownLawyerForm,
      prev: 'ownlawyer_disclaimer',
      next: 'ownlawyer_files',
    },
  },
  {
    name: 'ownlawyer_files',
    component: SimpleForm,
    completion: 60,
    submit: {
      variables: [
        'firstName',
        'lastName',
        'firmName',
        'telephone',
        'email',
        'address',
        'postalCode',
        'city',
        'factureFile',
      ],
      path: '/claims/consultationJuridiqueExterne',
    },
    data: {
      title: "Votre facture d'honoraires",
      formSchema: ownLawyerFiles,
      fileUploadPath: 'consultationJuridiqueExterne',
      prev: 'ownlawyer_form',
      next: 'ownlawyer_end',
    },
  },
  {
    name: 'ownlawyer_end',
    component: Message,
    completion: 100,
    data: {
      title: 'Merci !',
      icon: '/images/icons/thanks.svg',
      content: [
        "Votre facture a bien été transmise à l'assureur.",
        <span key="text">
          Vous serez informé dans les prochains jours de sa recevabilité et si
          tout est conforme, du remboursement des honoraires.
        </span>,
        <div key="back" className="text-center mt-6">
          <button
            type="button"
            onClick={() => {
              window.location.href = '/';
            }}
            className="text-caarlblue"
          >
            Retour Accueil
          </button>
        </div>,
      ],
    },
  },
];

const meetingSchema: ClaimSchema = [
  {
    name: 'meeting_nextmeeting',
    component: MeetingList,
    completion: 100,
    data: {
      title: 'list-intro',
    },
  },
  {
    name: 'meeting_speciality',
    component: ChooseSpeciality,
    completion: 30,
    data: {
      title: 'speciality-intro',
      formSchema: specialityForm,
      autoSubmit: true,
      prev: 'choix_procedure',
      next: 'meeting_choosedate',
    },
  },

  {
    name: 'meeting_choosedate',
    component: ChooseDate,
    completion: 50,
    data: {
      title: 'choosedate-intro',
      prev: 'meeting_speciality',
      next: (data) => {
        if (data.lawyers.length > 1) return 'meeting_chooselawyer';
        return 'meeting_notes';
      },
    },
  },

  {
    name: 'meeting_chooselawyer',
    component: ChooseLawyer,
    completion: 50,
    data: {
      title: 'lawyers-intro',
      prev: 'meeting_choosedate',
      next: 'meeting_notes',
    },
  },
  {
    name: 'meeting_notes',
    component: SimpleForm,
    completion: 60,
    data: {
      title: 'comments-intro',
      fileUploadPath: 'rendezVousJuridique',
      formSchema: commentsForm,
      prev: 'meeting_choosedate',
      next: 'meeting_signandconfirm',
    },
  },
  {
    name: 'meeting_signandconfirm',
    component: SignAndConfirm,
    completion: 80,
    submit: {
      variables: [
        'lawyer',
        'date',
        'duration',
        'documents',
        'clientComment',
        'speciality',
        'timezone',
      ],
      path: '/claims/rendezVousJuridique',
    },
    data: {
      title: 'confirm-intro',
      prev: 'meeting_choosedate',
      next: 'meeting_nextmeeting',
    },
  },
];

const schemaVisio: ClaimSchema = [
  {
    name: 'meeting_juridicall',
    component: Juridicall,
    completion: 100,
    data: {},
  },
  {
    name: 'choix_procedure',
    component: ChooseProcedure,
    completion: 10,
    data: {
      title: 'Choisissez votre procédure',
      formSchema: procedureForm,
      autoSubmit: true,
      next: (data) => {
        if (data.procedure === 'meeting') return 'meeting_speciality';
        return 'ownlawyer_disclaimer';
      },
    },
  },
  ...meetingSchema,
  ...ownLawyerSchema,
];

const initializeData = async (userData): Promise<ClaimData | object> => {
  if (!userData.services?.rendezVousJuridique) return {};
  const specDef: specialitiesDef = await axios
    .get(`${API_URL}/lawyers/specialities`)
    .then((res) => res.data);
  const { claim: appointment } = userData.services?.rendezVousJuridique
    .appointment
    ? await axios
        .get(
          `${API_URL}/claims/${userData.services.rendezVousJuridique.appointment}`
        )
        .then((res) => res.data)
    : { claim: null };
  const userConf = userData.services?.rendezVousJuridique;

  // timezone
  const localOffset = dayjs().local().format('ZZ');
  const appOffset = dayjs().tz().format('ZZ');

  return {
    userId: userData.number,
    specialitiesDefinitions: specDef,
    specialities: userConf.specialities,
    appointment,
    appointmentId: userConf.appointment,
    duration: userConf.types[0].duration,
    prebook: userConf.prebook,
    insurer: userConf.insurer,
    generateCH: userConf.generateCH,
    timezone: localOffset !== appOffset ? dayjs.tz.guess() : undefined,
  };
};

const exported = {
  title: 'Consultation juridique',
  schema: schemaVisio,
  initializeData,
};
export default exported;
