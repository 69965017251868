/* eslint-disable react/no-array-index-key */

const getParagraphsFromArray = (array) =>
  array.map((el, idx) => (
    <div key={`p${idx}`} className="mb-4">
      {el}
    </div>
  ));

const Message = (props) => {
  const content = Array.isArray(props.content)
    ? getParagraphsFromArray(props.content)
    : props.content;

  return (
    <div className="container-centered">
      <div className="text-title">{props.title}</div>
      {props.icon && (
        <div className="mt-24 mb-12">
          <img
            src={props.icon}
            alt={props.title}
            className="mx-auto w-32 h-32"
          />
        </div>
      )}
      <div className="text-darkblue text-lg">{content}</div>
    </div>
  );
};

export default Message;
