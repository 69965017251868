import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaUndoAlt } from 'react-icons/fa';
import { HiOutlineVideoCamera } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { UPDATE_CLAIM } from '../../../redux/reducers/claimReducer';
import { SimpleFormArgs } from '../../../types/Forms';
import DateHead from './DateHead';
import LawyerContent from './LawyerContent';

const BlockLawyer = ({
  onChoose,
  picture,
  firmName,
  speciality,
  description,
}) => (
  <button
    type="button"
    onClick={onChoose}
    aria-label="lawyer"
    className="button bg-white text-black w-full shadow-lg my-3 rounded-lg border border-white hover:bg-gray-50 hover:border-grey-300"
  >
    <LawyerContent
      picture={picture}
      firmName={firmName}
      description={description}
      info1={speciality}
    />
  </button>
);

const ChooseLawyer = ({ goTo, next, prev, claimData }: SimpleFormArgs) => {
  const { t } = useTranslation(['form', 'common']);
  const dispatch = useDispatch();
  const datetime = claimData.date;
  const specLabel = useMemo(() => {
    const [level1, level2] = claimData.speciality.split('_');
    if (!level2)
      return claimData.specialitiesDefinitions[claimData.speciality].title;
    return claimData.specialitiesDefinitions[level1]?.children[
      claimData.speciality
    ].title;
  }, []);

  const onChoose = (lawyerData) => {
    dispatch({
      type: UPDATE_CLAIM,
      data: { lawyer: lawyerData._id, lawyerObj: lawyerData },
    });
    goTo(next, { lawyer: lawyerData._id, lawyerObj: lawyerData });
  };

  return (
    <div className="container-centered">
      <div className="text-title">Vous avez choisi :</div>
      <div className="p-3 text-lg rounded-xl bg-darkblue text-white my-10">
        <DateHead datetime={datetime} />
        <div className="mt-3">
          en{' '}
          <div className="inline-block align-middle">
            <span className="w-8 h-8 text-base bg-caarlgreen rounded-full text-darkblue leading-8 text-center mx-1 flex items-center justify-center">
              <HiOutlineVideoCamera className="fill-none" />
            </span>
          </div>{' '}
          visio conférence
        </div>
      </div>
      <div className="text-title">
        {t(
          'Il y a plusieurs avocats disponibles sur ce créneau, avec qui voudriez-vous échanger ?'
        )}
      </div>
      {claimData.lawyers.map((lawyer) => (
        <BlockLawyer
          key={lawyer._id}
          onChoose={() => onChoose(lawyer)}
          firmName={`${lawyer.firstName} ${lawyer.lastName}`}
          description={lawyer.description}
          picture={lawyer.picture}
          speciality={t(specLabel)}
        />
      ))}

      <button
        type="button"
        onClick={() => {
          goTo(prev);
        }}
        className="flex font-bold text-left items-center border-2 border-darkblue mx-auto px-12 py-2 mt-12 text-darkblue hover:bg-white rounded-lg"
      >
        <FaUndoAlt className="h-8 w-8 mr-5" />
        <div>
          Je veux choisir
          <br />
          une autre date
        </div>
      </button>
    </div>
  );
};

export default ChooseLawyer;
