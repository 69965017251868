import { Multiline } from '@caarl_assurance/front-lib';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { FaCalendarTimes, FaUndoAlt } from 'react-icons/fa';
import { HiOutlineVideoCamera } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { API_URL } from '../../../config/config';
import {
  REPLACE_CLAIM,
  UPDATE_CLAIM,
} from '../../../redux/reducers/claimReducer';
import { SimpleFormArgs } from '../../../types/Forms';
import { ClaimData } from '../../../types/claimdata';
import { useAxios, useIsFirstRender } from '../../../utils/hooks';
import UserContext from '../../auth/context';
import ConfirmModal from '../../utils/ConfirmModal';
import ShowOnTime from '../../utils/ShowOnTime';
import TimezoneWarning from '../../utils/TimezoneWarning';
import DateHead from './DateHead';
import LawyerContent from './LawyerContent';
import MeetingModal from './MeetingModal';

const MeetingList = ({ goTo, claimData, title, intro }: SimpleFormArgs) => {
  const { t } = useTranslation(['form', 'common']);
  const dispatch = useDispatch();
  const isFirstCall = useIsFirstRender();
  const { mutateUser } = useContext(UserContext);
  const axiosFetcher = useAxios();
  const [showGreets, setShowGreets] = useState(!!claimData.date); // TODO remove state
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmPostpone, setShowConfirmPostpone] = useState(false);
  const { appointment } = claimData;
  const [roomURL, setRoomURL] = useState(null);

  useEffect(() => {
    if (!claimData.userId) {
      return;
    }
    if (!claimData.appointment?.data?.date) {
      goTo(claimData.speciality ? 'meeting_choosedate' : 'meeting_speciality');
      return;
    }
    dispatch({
      type: UPDATE_CLAIM,
      data: {
        ...claimData,
        date: undefined,
        lawyer: undefined,
        lawyerObj: undefined,
      },
    });
  }, [JSON.stringify([claimData.userId, claimData.appointment])]);

  if (isFirstCall || !appointment?.data?.date) {
    return null;
  }

  const deleteAppointment = async (goto = 'meeting_speciality') => {
    try {
      await axiosFetcher.patch(
        `${API_URL}/claims/${appointment.id || appointment.number}/delete`
      );
      const {
        userId,
        specialitiesDefinitions,
        duration,
        specialities,
        insurer,
        prebook,
        generateCH,
      } = claimData;
      const keepData: ClaimData = {
        userId,
        specialitiesDefinitions,
        duration,
        specialities,
        appointment: undefined,
        insurer,
        prebook,
        generateCH,
      };
      if (goto === 'meeting_choosedate') {
        keepData.speciality = appointment.data.speciality;
        keepData.clientComment = appointment.data.clientComment;
        keepData.documents =
          appointment.data.documents.map((doc) => ({
            id: doc._id,
            inputValue: doc.name,
            inputFiles: [{ name: doc.name }],
          })) || undefined;
      }

      await dispatch({
        type: REPLACE_CLAIM,
        data: {
          ...keepData,
        },
      });
      // goTo(goto);
      mutateUser();
    } catch (error) {
      toast.error(
        error.response?.data?.errors?.date ||
          t('Il y a eu une erreur lors de la suppression du rendez-vous.')
      );
    }
  };

  const getMeeting = async () => {
    const { roomURL: rURL } = await axiosFetcher.patch(
      `${API_URL}/claims/${appointment.id || appointment.number}/meetingurl`
    );
    setRoomURL(rURL);
  };

  return (
    <div className="container-centered">
      {title && <div className="text-title">{t(title)}</div>}
      <TimezoneWarning label={t('timezone-warning', { ns: 'common' })} />
      {roomURL && (
        <MeetingModal onClose={() => setRoomURL(null)} roomURL={roomURL} />
      )}
      {showGreets && (
        <div className="rounded-md bg-caarlgreen leading-tight relative px-10 mb-6 py-4 flex items-center">
          <button
            onClick={() => setShowGreets(false)}
            type="button"
            className="text-white absolute top-1 right-2"
          >
            &times;
          </button>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 146 151"
            fill="none"
            stroke="none"
            className="h-16 w-16 mr-8 stroke-white"
          >
            <path
              fill="#FFF"
              d="M137.26 4.25l-65.38 70.4-20.09-27.87a4.92 4.92 0 10-7.96 5.75l23.59 32.76a4.92 4.92 0 003.69 2.03h.3a4.92 4.92 0 003.6-1.64l69.45-74.74a4.91 4.91 0 10-7.21-6.7h.01z"
            />
            <path
              fill="#4285F4"
              d="M133.8 52.6a4.92 4.92 0 00-3.85 5.78 60.66 60.66 0 11-30.63-41.23 4.93 4.93 0 104.73-8.66 70.5 70.5 0 1035.54 47.96 4.92 4.92 0 00-5.79-3.85z"
            />
          </svg>
          <div>
            <div className="text-lg text-white font-bold leading-tight">
              {t('Votre rendez-vous de consultation est confirmé !')}
            </div>
            <div className="text-sm mt-2">
              {t(
                'Vous allez recevoir un mail de confirmation avec un lien pour demarrer la consultation.'
              )}
            </div>
          </div>
        </div>
      )}
      {intro && <Multiline className="text-subtitle">{t(intro)}</Multiline>}
      <div className="bg-white rounded-lg my-5 border border-grey-200 ">
        <div className="rounded-t-lg bg-darkblue text-white p-3 text-lg flex items-center justify-between w-full">
          <div>
            <DateHead datetime={appointment.data.date} />
          </div>
          <div className="align-middle w-8 h-8 text-base bg-caarlgreen rounded-full text-darkblue leading-8 text-center mx-1 flex items-center justify-center">
            <HiOutlineVideoCamera className="h-5 w-5 fill-none" />
          </div>
        </div>
        <div className="p-4 border-b border-b-grey-300">
          <LawyerContent
            firmName={`${appointment.lawyer.firstName} ${appointment.lawyer.lastName}`}
            description={appointment.lawyer.description}
            picture={appointment.lawyer.picture}
            info1={t(appointment?.data?.specialityLabel.title)}
          />
        </div>
        <div className="p-4 border-b border-b-grey-300">
          <p>
            Lorsque ce sera le moment, vous pourrez cliquer ci-dessous pour
            rejoindre la consultation
          </p>
          <ShowOnTime
            min={appointment.data.meetingBounds.min}
            max={appointment.data.meetingBounds.max}
            inProps={{}}
            outProps={{ disabled: true }}
          >
            <button
              type="button"
              onClick={getMeeting}
              className="flex font-bold text-left items-center mx-auto px-5 py-3 mt-6 mb-4 rounded-lg bg-caarlblue hover:bg-caarlblue/75 text-white disabled:bg-grey-300/60 "
            >
              <div className="rounded-full flex items-center justify-center w-10 h-10 p-2 border border-white mr-3">
                <HiOutlineVideoCamera className="h-7 w-7 fill-none" />
              </div>
              <div className="leading-tight">
                Rejoindre
                <br />
                la consultation
              </div>
            </button>
          </ShowOnTime>
        </div>
        <ShowOnTime
          min={appointment.data.meetingBounds.min}
          max={appointment.data.meetingBounds.max}
          inProps={{ className: 'hidden' }}
          outProps={{ disabled: true }}
        >
          <div className="p-4 border-b border-b-grey-300 flex items-center justify-between text-sm">
            <button
              onClick={() => setShowConfirmPostpone(true)}
              type="button"
              className="text-caarlblue mx-3"
            >
              <FaUndoAlt className="align-top inline-block h-4 w-4 mr-2" />{' '}
              Déplacer le RDV
            </button>
            <button
              onClick={() => setShowConfirmDelete(true)}
              type="button"
              className="text-red-500 mx-3"
            >
              <FaCalendarTimes className="align-top inline-block h-4 w-4 mr-2" />{' '}
              Annuler le RDV
            </button>
          </div>
        </ShowOnTime>
        {showConfirmDelete && (
          <ConfirmModal
            onClose={() => setShowConfirmDelete(false)}
            onConfirm={() => deleteAppointment()}
          >
            <div className="text-lg leading-tight mb-2">
              Êtes-vous sûr(e) de vouloir supprimer ce rendez-vous&nbsp;?
              <br />
            </div>
            <div className="text-violet-700 leading-tight">
              {dayjs().isBefore(dayjs(appointment.data.freeCancelLimit))
                ? 'il ne vous sera pas comptabilisé dans votre quota.'
                : ' Vu sa proximité, il sera déduit de votre quota.'}
            </div>
          </ConfirmModal>
        )}
        {showConfirmPostpone && (
          <ConfirmModal
            onClose={() => setShowConfirmPostpone(false)}
            onConfirm={() => deleteAppointment('meeting_choosedate')}
          >
            <div className="text-lg leading-tight mb-2">
              <span className="font-bold">
                Êtes-vous sûr(e) de vouloir déplacer ce rdv&nbsp;?
              </span>
              <br />
              Il va être d&apos;abord supprimé, et si vous n&apos;en prenez pas
              d&apos;autre, il sera considéré comme annulé.
            </div>

            {dayjs().isAfter(dayjs(appointment.data.freeCancelLimit)) && (
              <div className="text-violet-700 leading-tight">
                En outre, vu sa proximité, il sera déduit de votre quota.
              </div>
            )}
          </ConfirmModal>
        )}
      </div>
    </div>
  );
};

export default MeetingList;
