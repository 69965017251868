const LawyerContent = ({
  picture,
  firmName = '',
  description = '',
  info1 = null,
  info2 = null,
}: {
  picture: string;
  firmName?: string;
  description: string;
  info1?: string | null;
  info2?: string | null;
}) => (
  <div className="flex items-center w-full">
    <div className="mx-8">
      <img
        alt="person"
        src={picture || '/images/icons/person.svg'}
        className="rounded-full border-2 border-black w-12"
      />
    </div>
    <div className="text-left flex-grow font-bold">
      {firmName}
      <br />
      <div className="font-normal leading-1 text-sm text-neutral-400">
        {description}
      </div>
      <div className="flex justify-between items-center mt-4 w-full">
        {info1 && (
          <div className="text-xs text-white bg-neutral-400 px-2 py-1 rounded-md inline-block mr-1">
            {info1}
          </div>
        )}
        {info2 && (
          <div className="text-xs text-white bg-darkblue px-2 py-1 rounded-md inline-block">
            {info2}
          </div>
        )}
      </div>
    </div>
  </div>
);

export default LawyerContent;
