import { useEffect, useState } from 'react';
import { SimpleFormArgs } from '../../../types/Forms';
import SimpleForm from '../SimpleForm';

const ChooseProcedure = ({ goTo, claimData, ...rest }: SimpleFormArgs) => {
  const [render, setRender] = useState(false);
  useEffect(() => {
    if (!claimData.userId) {
      return;
    }
    if (claimData.prebook && !claimData.appointment) {
      goTo('meeting_juridicall');
    }
    if (claimData.appointment?.data?.date) {
      goTo('meeting_nextmeeting');
      return;
    }
    setRender(true);
  }, [claimData.userId, JSON.stringify(claimData.appointment)]);
  if (!render) return null;

  return <SimpleForm {...{ goTo, claimData, ...rest }} />;
};

export default ChooseProcedure;
