import { useFieldApi } from '@data-driven-forms/react-form-renderer';
import { ReactSVG } from 'react-svg';

const ChoiceButton = ({ name, option, className, onChange }) => {
  const { input } = useFieldApi({
    name,
    type: 'radio',
    value: option.value,
  });
  const el = option;
  return (
    <button
      type="button"
      className={`bg-caarlgreen text-white text-left font-bold shadow-md w-full flex gap-3 my-3 p-6 text-base leading-6 justify-between items-center rounded-lg ${className}`}
      onClick={() => onChange(input.value)}
    >
      <div className="flex-shrink-0" style={{ flexBasis: '50px' }}>
        <ReactSVG
          src={`${
            window.location.href.indexOf('localhost') !== -1
              ? '/api/proxy?file='
              : ''
          }${el.icon}`}
          className="force-white-fill-path"
          title={el.label}
          beforeInjection={(svg) => {
            svg.classList.add('test');
          }}
        />
      </div>
      <div className="flex-grow">
        {el.label}
        {el.sub && (
          <span className="block text-sm font-normal opacity-75 leading-tight">
            {el.sub}
          </span>
        )}
      </div>
    </button>
  );
};

export default ChoiceButton;
