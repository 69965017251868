import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SimpleFormArgs } from '../../../types/Forms';
import UserContext from '../../auth/context';
import { UserData } from '../../auth/types';
import SimpleForm from '../SimpleForm';
import NoCredits from './NoCredits';

const ChooseSpeciality = ({
  formSchema,
  claimData,
  goTo,
  title,
  ...rest
}: SimpleFormArgs) => {
  const { t } = useTranslation(['form', 'common']);

  const [base, setBase] = useState(claimData.specialitiesDefinitions);
  const [render, setRender] = useState(false);
  const { user } = useContext(UserContext);
  const { rendezVousJuridique } = (user as UserData).services as {
    rendezVousJuridique: { maxClaims: number; currentPeriodClaims: number };
  };
  const claimsLeft =
    rendezVousJuridique.maxClaims - rendezVousJuridique.currentPeriodClaims;

  useEffect(() => {
    if (!claimData.userId) {
      return;
    }
    if (claimData.appointment?.data?.date) {
      goTo('meeting_nextmeeting');
      return;
    }
    setRender(true);
  }, [claimData.userId, JSON.stringify(claimData.appointment)]);

  const formSchemaFilled = useMemo(() => {
    const nuFS = { ...formSchema };
    const specsBrutes = [
      ...new Set(
        claimData.specialities.concat(
          claimData.specialities.map((s) => s.split('_').at(0))
        )
      ),
    ];
    nuFS.fields[0].options = Object.entries(base)
      .map(([key, value]) => {
        if (specsBrutes.indexOf(key) !== -1) {
          return {
            value: key,
            label: value.title,
            sub: value.subtitle,
            className: 'h-28',
            subClass: 'line-clamp-2 ',
            labelClass: 'line-clamp-2 leading-tight',
            icon: value.icon,
          };
        }
        return null;
      })
      .filter((x) => x);

    return nuFS;
  }, [JSON.stringify(base), claimData.specialities]);

  const goToBypass = (dest, data) => {
    if (dest !== rest.next) {
      return goTo(dest, data);
    }
    const { speciality } = data;
    if (claimData.specialitiesDefinitions[speciality]?.children) {
      setBase(claimData.specialitiesDefinitions[speciality]?.children);
      return Promise.resolve();
    }
    return goTo(dest, data);
  };

  if (!render) return null;

  return (
    <>
      <div className="flex justify-between items-center container-centered !pb-0">
        {title && <div className="text-title">{t(title, { ns: 'form' })}</div>}
        <div className="text-center leading-4 text-base bg-caarlblue/20 text-caarlblue py-1 px-3 rounded-lg">
          <span className="font-thin text-xs whitespace-nowrap">
            Il vous reste
          </span>
          <br />
          {claimsLeft}&nbsp;{claimsLeft > 1 ? 'crédits' : 'crédit'}
        </div>
      </div>
      {claimsLeft === 0 && <NoCredits />}
      {claimsLeft > 0 && (
        <SimpleForm
          {...{
            formSchema: formSchemaFilled,
            goTo: goToBypass,
            claimData,
            ...rest,
          }}
        />
      )}
    </>
  );
};

export default ChooseSpeciality;
