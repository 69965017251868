import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { FaUndoAlt, FaVideo } from 'react-icons/fa';
import useSWR from 'swr';
import { API_URL } from '../../../config/config';
import { SimpleFormArgs } from '../../../types/Forms';
import { useAxios } from '../../../utils/hooks';
import TimezoneWarning from '../../utils/TimezoneWarning';
import DateHead from './DateHead';
import LawyerContent from './LawyerContent';

const SignAndConfirm = ({ goTo, prev, next, claimData }: SimpleFormArgs) => {
  const axiosFetcher = useAxios();
  const datetime = dayjs(claimData.date).tz().toDate();
  const [libreChoix, setLibreChoix] = useState(false);
  const [valideCH, setValideCH] = useState(false);
  const {
    lawyer,
    lawyerObj,
    speciality,
    specialitiesDefinitions,
    userId,
    generateCH,
  } = claimData;
  const { data: chContent, error } = useSWR(
    generateCH
      ? `${API_URL}/lawyers/${lawyer}/conventionHonoraires/${userId}`
      : null,
    axiosFetcher.get
  );
  const isLoading = !chContent && !error;
  const specLabel = useMemo(() => {
    const [level1, level2] = speciality.split('_');
    if (!level2) return specialitiesDefinitions[speciality].title;
    return specialitiesDefinitions[level1]?.children[speciality].title;
  }, []);
  return (
    <div className="container-centered">
      <TimezoneWarning />
      <div className="text-title">Votre rendez-vous</div>
      <div className="rounded-lg bg-white shadow-md">
        <div className="rounded-t-lg bg-darkblue text-white p-3 text-lg flex items-center justify-between w-full mt-6">
          <div>
            <DateHead datetime={datetime} />
          </div>
          <div className="align-middle w-8 h-8 text-base bg-caarlgreen rounded-full text-darkblue leading-8 text-center mx-1 flex items-center justify-center">
            <FaVideo className="inline" />
          </div>
        </div>
        <div className="p-4 border-b border-b-grey-300">
          <LawyerContent
            firmName={`${lawyerObj.firstName} ${lawyerObj.lastName}`}
            description={lawyerObj.description}
            picture={lawyerObj.picture}
            info1={specLabel}
          />
        </div>
        {generateCH && (
          <>
            <div className="p-4 border-b border-b-grey-300">
              <label className="flex items-start ">
                <input
                  type="checkbox"
                  className="mr-4 mt-1 peer"
                  checked={libreChoix}
                  onChange={(e) => setLibreChoix(e.currentTarget.checked)}
                />
                <span className=" text-base leading-tight text-grey-100 peer-checked:text-darkblue">
                  Je confirme avoir bénéficié du libre choix de mon avocat et
                  avoir sollicité ma protection juridique afin de me mettre en
                  relation avec son réseau d&apos;avocats.
                </span>
              </label>
            </div>
            <div className="p-4 border-b border-b-grey-300">
              <div
                id="chFrame"
                className="border-2 rounded-md border-grey-300 p-2 h-40 overflow-y-auto"
              >
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: isLoading ? 'chargement...' : chContent,
                  }}
                />
              </div>
              <label className="flex items-start mt-4">
                <input
                  type="checkbox"
                  className="mr-4 mt-1 peer"
                  checked={valideCH}
                  onChange={(e) => setValideCH(e.currentTarget.checked)}
                />
                <span className=" text-base leading-tight text-grey-100 peer-checked:text-darkblue">
                  Je valide la convention d&apos;honoraires de l&apos;avocat.
                </span>
              </label>
            </div>
          </>
        )}
        <div className="p-6 text-center">
          <button
            type="button"
            disabled={generateCH && (!valideCH || !libreChoix || isLoading)}
            onClick={() => goTo(next, {}, true)}
            className="btn text-sm mx-auto px-8 py-4 rounded-md hover:bg-caarlblue/80"
          >
            Confirmer le Rendez-vous
          </button>
        </div>
      </div>
      <button
        type="button"
        onClick={() => {
          goTo(prev, {}, false);
        }}
        className="flex font-bold text-left items-center border-2 border-darkblue mx-auto px-12 py-2 mt-12 text-darkblue hover:bg-white rounded-lg"
      >
        <FaUndoAlt className="h-8 w-8 mr-5" />
        <div>
          Choisir une autre date
          <br />
          ou un autre avocat
        </div>
      </button>
    </div>
  );
};

export default SignAndConfirm;
