import { Multiline } from '@caarl_assurance/front-lib';
import { useEffect, useRef } from 'react';
import { SimpleFormArgs } from '../../types/Forms';

const SimpleContent = ({ goTo, title, intro, content }: SimpleFormArgs) => {
  const root = useRef(null);

  useEffect(() => {
    root.current.querySelectorAll('*[data-trigger]').forEach((element) => {
      element.addEventListener('click', () => {
        goTo(element.attributes['data-trigger'].value);
      });
    });
  }, []);

  return (
    <div ref={root} className="container-centered">
      {title && <div className="text-title">{title}</div>}
      {intro && <Multiline className="text-subtitle">{intro}</Multiline>}
      {content}
    </div>
  );
};

export default SimpleContent;
