import { Multiline } from '@caarl_assurance/front-lib';
import * as Sentry from '@sentry/nextjs';
import dayjs from 'dayjs';
import { useContext, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { FaRegCalendar } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import useSWR from 'swr';
import { API_URL, MEETING_API_URL } from '../../../config/config';
import { UPDATE_CLAIM } from '../../../redux/reducers/claimReducer';
import { SimpleFormArgs } from '../../../types/Forms';
import { specialityDef } from '../../../types/claimdata';
import { useAxios } from '../../../utils/hooks';
import UserContext from '../../auth/context';
import { UserData } from '../../auth/types';
import TimezoneWarning from '../../utils/TimezoneWarning';
import { getSpecialityFromDefinitions } from './common';

const BlockDate = ({ date, times: timeList, onChooseDate }) => (
  <div className="bg-white rounded-lg my-5 shadow-md">
    <div className="p-4 text-base border-b border-b-grey-300 text-caarlblue leading-none">
      <FaRegCalendar className="inline align-middle text-lg mr-2 h-6 w-6" />{' '}
      <span className="capitalize align-middle">
        {dayjs(date).tz().format('dddd D MMMM')}
      </span>
    </div>
    <div className="p-4 grid justify-between grid-cols-[repeat(3,minmax(5rem,max-content))]">
      {Object.entries(timeList).map(([time, lawyers]) => (
        <div key={time} className="w-1/4 text-center mb-3">
          <button
            className="text-center py-2 px-4 xs:py-2 xs:px-8 rounded-md bg-caarlblue text-white mb-3"
            type="button"
            onClick={() => onChooseDate({ time, lawyers })}
          >
            {dayjs(time).tz().format('HH:mm')}
          </button>
        </div>
      ))}
    </div>
  </div>
);

const EmptySlots = ({
  email,
  speciality,
  legalCountry,
  specDef,
}: {
  speciality: string;
  specDef: specialityDef;
  email: string;
  legalCountry: string;
}) => {
  const { t } = useTranslation(['form']);
  const { post } = useAxios();
  const [isLoading, setLoading] = useState(false);
  const [isSent, setSent] = useState(false);

  const onClick = async () => {
    setLoading(true);
    try {
      await post('/api/alerteDispo', { speciality, email, legalCountry });
      setSent(true);
    } catch (error) {
      toast.error('Error sending message');
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-centered">
      {isLoading && (
        <div className="text-grey-100 flex text-center items-center justify-center mt-32 flex-wrap box-content">
          <ClipLoader size={60} />
          <div className="w-full mt-4">
            {t("Veuillez patienter pendant l'envoi des données...")}
          </div>
        </div>
      )}
      {!isLoading && !isSent && (
        <>
          <div className="text-title">
            Vous souhaitez prendre rendez-vous avec un avocat en&nbsp;:
          </div>
          <div className="bg-white font-bold border border-grey-300 w-full flex my-6 p-6 text-base leading-6 justify-between items-center rounded-lg">
            <div
              className="flex-shrink-0 relative  mr-3"
              style={{ flexBasis: '50px' }}
            >
              <img className="" alt="" src={specDef.icon} />
            </div>
            <div className="flex-grow text-left">
              {specDef.title}
              <span className="block text-sm font-normal opacity-75 leading-none">
                {specDef.subtitle}
              </span>
            </div>
          </div>
          <div className="text-normal my-12">
            Pour confirmer votre demande de RDV et recevoir un email avec des
            disponibilités d’avocats, cliquez-ici&nbsp;:
          </div>
          <div className="text-center">
            <button
              onClick={onClick}
              type="button"
              className="btn rounded-md inline-block px-6 py-4"
            >
              Confirmer ma demande de RDV
            </button>
          </div>
        </>
      )}
      {!isLoading && isSent && (
        <>
          <div className="text-title">Merci !</div>
          <div className="text-normal mt-4 text-grey-500">
            {t(
              'Nous contactons nos avocats experts et vous envoyons un email avec des disponibilités dès que possible.'
            )}
          </div>
          <div className="text-center mt-8">
            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
            <a href="/" className="btn">
              Retour
            </a>
          </div>
        </>
      )}
    </div>
  );
};

const ChooseDate = ({
  title,
  intro,
  goTo,
  next,
  claimData,
}: SimpleFormArgs) => {
  const { t } = useTranslation(['form', 'common']);
  const axiosFetcher = useAxios();
  const { user } = useContext(UserContext);
  const dispatch = useDispatch();
  const from = dayjs().add(1, 'day').format('YYYY-MM-DD');
  const to = dayjs().add(1, 'month').format('YYYY-MM-DD');
  const { speciality, specialitiesDefinitions } = claimData;

  const { data: { slots } = {}, error } = useSWR(
    `${MEETING_API_URL}/availabilities/slots?from=${from}&to=${to}&specialities[]=${speciality}&duration=30`,
    axiosFetcher.get
  );

  const dateList = useMemo(() => {
    if (!slots) return {};
    const list = {};
    slots.forEach((element) => {
      const date = dayjs(element.start).tz().startOf('day').toString();
      if (!list[date]) {
        list[date] = {};
      }
      if (!list[date][element.start]) {
        list[date][element.start] = [];
      }
      list[date][element.start].push(element.lawyer);
    });
    return list;
  }, [slots]);

  const onChooseDate = async ({ time, lawyers }) => {
    const lawyersData = await axiosFetcher.get(`${API_URL}/lawyers`, {
      ids: lawyers,
    });
    const lawyer =
      lawyersData.length > 1
        ? {}
        : // eslint-disable-next-line no-underscore-dangle
          { lawyer: lawyersData.at(0)._id, lawyerObj: lawyersData.at(0) };
    dispatch({
      type: UPDATE_CLAIM,
      data: { date: time, lawyers: lawyersData, ...lawyer },
    });
    goTo(next, { date: time, lawyers: lawyersData });
  };

  const isEmpty = !error && slots?.length === 0;

  if (isEmpty) {
    return (
      <EmptySlots
        legalCountry={(user as UserData).legalCountry}
        email={user.email}
        speciality={speciality}
        specDef={getSpecialityFromDefinitions(
          speciality,
          specialitiesDefinitions
        )}
      />
    );
  }

  return (
    <div className="container-centered">
      {title && <div className="text-title">{t(title)}</div>}
      <TimezoneWarning label={t('timezone-warning', { ns: 'common' })} />

      {intro && <Multiline className="text-subtitle">{t(intro)}</Multiline>}
      {error && <div className="text-red-500 my-4">{error}</div>}
      {Object.entries(dateList).map(([date, times]) => (
        <BlockDate
          key={date}
          date={date}
          times={times}
          onChooseDate={onChooseDate}
        />
      ))}
    </div>
  );
};

export default ChooseDate;
